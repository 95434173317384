import React from 'react';

import { Heading } from 'components/v2/Typography';

import { InfoContainer } from './InfoContainer';
import { BlockText } from './LineItemsTable.styled';

interface Props {
  name: string;
  address: string;
  taxId: string;
  clinicLocation: string;
  renderingProvider: string | null;
  renderingNpi: string | null;
}

export const PayeeInfo = ({
  name,
  address,
  taxId,
  clinicLocation,
  renderingProvider,
  renderingNpi
}: Props) => {
  return (
    <InfoContainer>
      <Heading tag="h6" noMargin>
        FROM
      </Heading>
      <Heading tag="h4" noMargin>
        {name}
      </Heading>

      <BlockText>{address}</BlockText>
      {taxId && <BlockText>Tax ID: {taxId}</BlockText>}
      {clinicLocation && <BlockText>Clinic Location: {clinicLocation}</BlockText>}
      {renderingProvider && <BlockText>Rendering Provider: {renderingProvider}</BlockText>}
      {renderingNpi && <BlockText>Rendering NPI: {renderingNpi}</BlockText>}
    </InfoContainer>
  );
};
