import React, { useState } from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { DropdownIcon } from 'components/v2/DropdownMenu/Dropdown.styled';
import { usePatient } from 'hooks';

import { useCustomDashboardTiles } from '../CustomWidgets/CustomWidgets.hooks';
import { showCustomTile } from '../CustomWidgets/CustomWidgets.utils';
import { useMyJourney } from '../Dashboard.hooks';
import { useUpdateHiddenDashboardWidgets } from './DashboardSettings.hooks';
import { useHiddenDashboardWidgets } from './DashboardSettings.hooks';
import { Container } from './DashboardSettings.styled';
import { DashboardWidgetList } from './DashboardSettings.types';
import { SettingsModal } from './SettingsModal';

export const DashboardSettings = () => {
  const [open, setOpen] = useState(false);
  const { patient, loading: loadingUser } = usePatient();
  const { data: hiddenWidgets, loading: loadingHiddenWidgets } = useHiddenDashboardWidgets();
  const { data: journeyData, loading: loadingJourney } = useMyJourney();
  const { data: customTiles, loading: loadingCustomTiles } = useCustomDashboardTiles();
  const {
    updateHiddenDashboardWidgets,
    loading: updatingHiddenWidgets
  } = useUpdateHiddenDashboardWidgets();

  const displayableCustomTiles = customTiles?.customDashboardTiles.filter(showCustomTile) ?? [];

  const handleSave = (hiddenWidgets: DashboardWidgetList) => {
    updateHiddenDashboardWidgets({
      variables: {
        hiddenDashboardWidgets: hiddenWidgets
      }
    }).finally(() => setOpen(false));
  };

  if (!patient) return null;

  const showLoader =
    updatingHiddenWidgets ||
    loadingHiddenWidgets ||
    loadingUser ||
    loadingJourney ||
    loadingCustomTiles;

  return (
    <Container>
      <LinkButton leftIcon={<DropdownIcon />} size="sm" onClick={() => setOpen(true)} />

      {/*
        mount/unmount the whole modal from outside because the DashboardSettings component is rendered in
        multiple places(widgets) at the same time. We don't need to have multiple modals rendered(but closed) at the same time.
      */}
      {open && (
        <SettingsModal
          hiddenWidgets={hiddenWidgets ?? []}
          onClose={() => setOpen(false)}
          onSave={handleSave}
          showLoader={showLoader}
          patient={patient}
          journey={journeyData}
          customTiles={displayableCustomTiles}
        />
      )}
    </Container>
  );
};
