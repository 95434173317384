import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
  z-index: 1;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
