import React, { useEffect, useState } from 'react';

import { CalendarCheck, CalendarPlus, ChevronLeft, ChevronRight, Phone } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading, Text } from 'components/v2/Typography';
import { getTimeZone } from 'hooks';
import { Appointment } from 'kb-shared';
import theme from 'kb-shared/theme';
import {
  PHONE_CALL_DISCLAIMER,
  shouldDisplayProvider,
  VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER
} from 'kb-shared/utilities/appointment.helper';
import { inFuture } from 'kb-shared/utilities/momentHelpers';
import { compareByStartTimeAscending } from 'screens/Appointments/Appointments.utils';
import { ModalCancelReschedule } from 'screens/ModalCancelResched/ModalCancelResched';
import { generateCalendarLink } from 'utilities/appointmentProduct';
import getAppointmentChannel from 'utilities/getAppointmentChannel';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from '../../Widgets/Widgets.styled';
import {
  AppointmentButtons,
  AppointmentName,
  ButtonsContainer,
  Header,
  PhoneConsult,
  ProviderInfo,
  Sliders,
  UpcomingHeading,
  YellowBox
} from './UpcomingAppointmentsWidget.styled';
import { Props } from './UpcomingAppointmentsWidget.types';

export const UpcomingAppointmentsWidget = ({ upcomingAppointments, refetch }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [index, setIndex] = useState(0);
  const [sortedUpcomingAppointments, setSortedUpcomingAppointments] = useState<Appointment[]>([]);
  const { isDesktop } = useBreakpoints();

  useEffect(() => {
    setSortedUpcomingAppointments(upcomingAppointments.sort(compareByStartTimeAscending));
  }, [upcomingAppointments]);

  const toggleCancelModal = () => {
    setShowModal(!showModal);
  };

  const addToCalendar = () =>
    window.open(
      generateCalendarLink(
        {
          appointmentType: appointment.appointmentType,
          startTime: appointment.startTime,
          endTime: appointment.endTime,
          location: appointment.location
        },
        appointment.videoLink ?? appointment.appointmentType.videoLink
      )
    );

  const goToVideoLink = () => {
    const videoLink = appointment.videoLink ?? appointment.appointmentType.videoLink;

    if (!videoLink) return;

    window.open(videoLink, '_blank');
  };

  const onPrevious = () => {
    setIndex(Math.max(index - 1, 0));
  };

  const onNext = () => {
    setIndex(Math.min(index + 1, sortedUpcomingAppointments.length - 1));
  };

  if (sortedUpcomingAppointments.length === 0) return <></>;

  const appointment = sortedUpcomingAppointments[index];
  const { formatTime } = getTimeZone(appointment.timeZone, appointment.appointmentType);
  const isFuture = inFuture(appointment.endTime);
  const { isPhoneCall, isVideoCall } = getAppointmentChannel(appointment.appointmentType);

  return (
    <IllustratedBackgroundWidget variant={4}>
      <Header>
        <IconContainer $iconWidth={42} $iconHeight={48} $noMargin>
          <CalendarCheck type="solid" />
        </IconContainer>

        {sortedUpcomingAppointments.length > 1 && (
          <Sliders>
            <Button
              category="secondary"
              size="md"
              rightIcon={<ChevronLeft type="solid" />}
              onClick={onPrevious}
              isDisabled={index === 0}
            />
            <Button
              category="secondary"
              size="md"
              rightIcon={<ChevronRight type="solid" />}
              onClick={onNext}
              isDisabled={index === sortedUpcomingAppointments.length - 1}
            />
          </Sliders>
        )}
      </Header>

      <UpcomingHeading tag="h5" noMargin>
        {`Upcoming Appointment${sortedUpcomingAppointments.length > 1 ? 's' : ''}:`}
      </UpcomingHeading>

      <WidgetTitleContainer $noMargin>
        <Heading tag="div" styledAs="h2" color={theme.colors.neutral.lightNavy}>
          {formatTime(appointment.startTime, 'dddd, MMMM Do')}
        </Heading>
      </WidgetTitleContainer>

      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2" color={theme.colors.neutral.lightNavy}>
          {formatTime(appointment.startTime, 'h:mm A z')}
        </Heading>
      </WidgetTitleContainer>

      <AppointmentName size="sm" fontStyle="bold">
        {appointment.appointmentType.name}
      </AppointmentName>

      {shouldDisplayProvider(appointment) && appointment.providerName && (
        <ProviderInfo>
          <Text size="sm" fontStyle="semibold">
            Provider:{' '}
          </Text>
          <Text size="lg">{appointment.providerName}</Text>
        </ProviderInfo>
      )}

      <AppointmentButtons>
        {isVideoCall && isFuture && (
          <YellowBox>
            <Text>{VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER}</Text>
            <Button size="lg" label="JOIN VIDEO CONSULT" onClick={goToVideoLink} />
          </YellowBox>
        )}

        {isPhoneCall && isFuture && (
          <PhoneConsult>
            {isDesktop && (
              <IconContainer
                $height={48}
                $iconWidth={32}
                $iconHeight={32}
                $noMargin
                $color={theme.colors.yellow.darkYellow}
              >
                <Phone type="solid" />
              </IconContainer>
            )}
            <Text>{PHONE_CALL_DISCLAIMER}</Text>
          </PhoneConsult>
        )}

        {isFuture && (
          <ButtonsContainer>
            <Button
              size="lg"
              category="secondary"
              label="ADD TO CALENDAR"
              fullWidth
              leftIcon={<CalendarPlus type="solid" />}
              onClick={addToCalendar}
            />
            <LinkButton text="CANCEL OR RESCHEDULE" underline="none" onClick={toggleCancelModal} />
          </ButtonsContainer>
        )}
      </AppointmentButtons>

      <ModalCancelReschedule
        visible={showModal}
        onClose={toggleCancelModal}
        onReturnToDashboard={toggleCancelModal}
        appointment={appointment}
        onAppointmentCancelled={refetch}
        onReschedule={refetch}
      />
    </IllustratedBackgroundWidget>
  );
};
