import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import { Patient } from 'kb-shared';
import { QUERY_MEMBERSHIP } from 'kb-shared/graphql/queries';
import { QueryMembershipResponse } from 'kb-shared/graphql/queries_types';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { BenefitsWidget } from 'screens/Dashboard/MyBenefitsWidgets/BenefitsWidget/BenefitsWidget';
import { showErrorToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import { BenefitEmptyStateWrapper } from './Employer.styled';
import { Container } from './shared.styled';

export interface EmployerProps {
  patient: Patient;
}

export function Employer({ patient }: EmployerProps) {
  const patientMembership = patient?.patientMembership;
  const { data: membershipData, error } = useQuery<QueryMembershipResponse>(QUERY_MEMBERSHIP, {
    skip: !patientMembership || !patientMembership.membershipId,
    variables: { membershipId: patientMembership && patientMembership.membershipId }
  });
  const history = useHistory();

  useEffect(() => {
    if (error) {
      showErrorToast(
        `We failed to fetch your employer benefit information. Please reload the screen or contact us at ${KBContacts.navigatorEmail}`
      );
      BugTracker.notify(error, 'Failed to fetch employer benefit information');
    }
  }, [error]);

  return (
    <Container>
      {patient?.patientMembership?.employerName && (
        <div>
          <Heading tag="h2" styledAs="h4">
            Your Employer
          </Heading>
          <Text tag="span" transform="uppercase">
            {patient?.patientMembership?.employerName}
          </Text>
        </div>
      )}
      {patient?.patientMembership?.employer && membershipData?.membership?.description && (
        <BenefitsWidget
          benefitsDescription={membershipData.membership.description}
          patient={patient}
        />
      )}
      {!patient?.patientMembership?.employer && (
        <BenefitEmptyStateWrapper>
          <Heading tag="span" styledAs="h4" bg="inherit">
            Does your employer have the Kindbody benefit?
          </Heading>
          <Button
            label="ACTIVATE YOUR BENEFIT"
            size="lg"
            category="primary-dark"
            onClick={() => history.push(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE)}
          />
        </BenefitEmptyStateWrapper>
      )}
    </Container>
  );
}
