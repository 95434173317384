import React from 'react';

import { DashboardSettings } from '../DashboardSettings/DashboardSettings';
import { WidgetBaseContainer } from './WidgetBase.styled';

interface Props {
  className?: string;
  variant?: number;
  bgColor?: string;
}

export const WidgetBase = ({
  children,
  className,
  variant,
  bgColor
}: React.PropsWithChildren<Props>) => {
  return (
    <WidgetBaseContainer className={className} $variant={variant} $bgColor={bgColor}>
      <DashboardSettings />
      {children}
    </WidgetBaseContainer>
  );
};
